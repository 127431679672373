<template>
  <div id="max-score" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns is-multiline">
          <div class="column is-6 is-offset-3">
            <p class="title is-4 has-text-right">
              {{ maxScore.classGroup.name }}
            </p>
          </div>
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(update)">
              <SdInput
                type="text"
                rules="required"
                label="FA Name"
                v-model="maxScore.faName"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="FA Max Score"
                v-model="maxScore.faMaxMark"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="SA Name"
                v-model="maxScore.saName"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="SA Max Score"
                v-model="maxScore.saMaxMark"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="FT Name"
                v-model="maxScore.ftName"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="FT Max Score"
                v-model="maxScore.ftMaxMark"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="ST Name"
                v-model="maxScore.stName"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="ST Max Score"
                v-model="maxScore.stMaxMark"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="EX Name"
                v-model="maxScore.examsName"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                rules="required"
                label="EX Max Score"
                v-model="maxScore.examsMaxMark"
                placeholder="Enter a name"
              />

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import CLASS_GROUPS from '../../../graphql/class_group/ClassGroups.gql'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'MaxScore',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Max Score',
      skipQuery: true,
      classGroups: [],
      maxScore: {
        classGroup: {
          name: null,
        },
        faName: null,
        faMaxMark: null,
        saName: null,
        saMaxMark: null,
        ftName: null,
        ftMaxMark: null,
        stName: null,
        stMaxMark: null,
        examsName: null,
        examsMaxMark: null,
      },
    }
  },
  apollo: {
    maxScore: {
      query() {
        if (this.$route.params.id) {
          return gql`
            query MaxScoreQuery($id: ID!) {
              maxScore(id: $id) {
                id
                classGroup {
                  id
                  name
                }
                faName
                faMaxMark
                saName
                saMaxMark
                ftName
                ftMaxMark
                stName
                stMaxMark
                examsName
                examsMaxMark
              }
            }
          `
        }
      },
      variables() {
        return { id: this.$route.params.id }
      },
      fetchPolicy: 'no-cache',
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    maxScore(data) {
      this.$store.commit('setSubMenus', [
        {
          name: 'Max Score Info',
          route: `/school/${this.schoolId}/max_score_info/${data.classGroup.id}`,
        },
      ])
    },
  },
  methods: {
    update() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateMaxScoreQuery($id: Int!, $data: JSON!) {
              updateMaxScore(input: { id: $id, data: $data }) {
                maxScore {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.$route.params.id),
            data: this.maxScore,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateMaxScore.errors,
            'Successfully updated.'
          ).then(() => {
            this.$router.push(
              `/school/${this.$route.params.school_id}/max_scores`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)

    if (this.$route.params.id !== 'new') {
      this.skipQuery = false
    } else {
      this.$store.commit('setSubMenus', [
        {
          name: 'Max Scores',
          route: `/school/${this.schoolId}/max_scores`,
        },
      ])
    }
  },
}
</script>
